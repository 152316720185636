export default {
  namespaced: true,

  state: {
    dateSimple: null,
  },

  mutations: {
    setDateSimple(state, payload) {
      state.dateSimple = payload;
    },
  },

  actions: {
    actionDateSimple({ commit }, payload) {
      commit("setDateSimple", payload);
    },
  },

  getters: {},
};
