export default {
  getAll() {},
  find() {},
  save() {},
  delete() {},
  update() {},
  edit() {},
  login() {},
  register() {},
  timezone() {},
  image() {},
  stepGroup() {},
  stepAttributes() {},
  getMultimedia() {},
  getAllDeparts() {},
  getAllDepartsSiigo() {},
  getAllMuniciByDepart() {},
  getAllPriorities() {},
  getAllInvestments() {},
  getAllNames() {},
  uploadImage() {},
  farmFavorite() {},
  findFarmPreview() {},
  passwordRecovery() {},
  validateCode() {},
  changePassword() {},
  getRol() {},
  verifyIdentification() {},
};
