<template>
  <section class="bg-agrogo-green-2">
    <div class="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 content-desktop">
      <div class="">
        <img class="w-full h-full" src="@/assets/images/modalcover.png" />
      </div>
      <div class="">
        <div
          class="flex flex-col items-center justify-center space-y-1 pt-5 pb-8 md:items-start px-8 md:px-14 md:pt-12 md:pb-0"
        >
          <p
            class="font-dinpro font-bold text-agrogo-yellow-1 text-xl md:text-3xl"
          >
            Bienvenido a
          </p>
          <img
            class="w-32 pb-8 md:w-80 md:pb-12"
            srcset="@/assets/images/imagesHeader/agrogofincas.svg"
            src="@/assets/images/imagesHeader/agrogofincas.png"
            alt="AGROGO Fincas"
          />
          <p
            class="font-nexa font-black text-sm text-agrogo-yellow-1 text-left md:text-2xl md:font-normal pb-8 md:pb-12"
          >
            ¿Quiéres que te ayudemos a encontrar la finca perfecta para ti?
          </p>
        </div>
        <div
          class="flex flex-col items-center justify-center pb-14 space-y-4 md:space-y-7 md:pb-20"
        >
          <button
            type="button"
            @click.prevent="() => $emit('buy')"
            class="btn-primary flex items-center justify-center w-48 h-8 bg-agrogo-yellow-1 font-nexa font-bold text-base text-agrogo-green-2 rounded-lg md:w-80 md:h-14 md:text-2xl"
          >
            Quiero comprar
            <span
              class="icon-rocket text-sm pl-2 leading-none md:text-2xl md:pl-4"
            ></span>
          </button>
          <button
            type="button"
            @click.prevent="() => $emit('explore')"
            class="btn-primary flex items-center justify-center w-48 h-8 bg-agrogo-yellow-1 font-nexa font-normal text-sm text-agrogo-green-2 rounded-lg md:w-80 md:h-14 md:text-2xl"
          >
            Quiero explorar
            <span
              class="icon-lupa text-sm pl-4 leading-none md:text-2xl md:pl-4"
            ></span>
          </button>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 content-mobile">
      <div
        class="flex flex-col items-center justify-center space-y-1 pt-5 pb-8 md:items-start px-8 md:px-14 md:pt-12 md:pb-0"
      >
        <p
          class="font-dinpro font-bold text-agrogo-yellow-1 text-xl md:text-3xl"
        >
          Bienvenido a
        </p>
        <img
          class="w-32 pb-8 md:w-80 md:pb-12"
          srcset="@/assets/images/imagesHeader/agrogofincas.svg"
          src="@/assets/images/imagesHeader/agrogofincas.png"
          alt="AGROGO Fincas"
        />
        <p
          class="font-nexa font-black text-sm text-agrogo-yellow-1 text-left px-6"
        >
          ¿Quiéres que te ayudemos a encontrar la finca perfecta para ti?
        </p>
      </div>
      <div class="mb-8">
        <img class="w-full h-full" src="@/assets/images/modalcovermovil.png" />
      </div>
      <div
        class="flex flex-col items-center justify-center pb-14 space-y-4 md:space-y-7 md:pb-20"
      >
        <button
          type="button"
          @click.prevent="() => $emit('buy')"
          class="btn-primary flex items-center justify-center w-48 h-8 bg-agrogo-yellow-1 font-nexa font-bold text-base text-agrogo-green-2 rounded-lg md:w-80 md:h-14 md:text-2xl"
        >
          Quiero comprar
          <span
            class="icon-rocket text-sm pl-2 leading-none md:text-2xl md:pl-4"
          ></span>
        </button>
        <button
          type="button"
          @click.prevent="() => $emit('explore')"
          class="btn-primary flex items-center justify-center w-48 h-8 bg-agrogo-yellow-1 font-nexa font-normal text-sm text-agrogo-green-2 rounded-lg md:w-80 md:h-14 md:text-2xl"
        >
          Quiero explorar
          <span
            class="icon-lupa text-sm pl-4 leading-none md:text-2xl md:pl-4"
          ></span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="css">
.content-mobile {
  display: none !important;
}

.content-desktop {
  display: block;
}

@media only screen and (max-width: 767px) {
  .content-mobile {
    display: block !important;
  }

  .content-desktop {
    display: none !important;
  }
}
</style>
