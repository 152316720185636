export default {
  methods: {
    messageValidation(error) {
      let text = null;

      if (error.required && error.required.$invalid) {
        text = "Campo obligatorio";
      } else if (error.email && error.email.$invalid) {
        text = "Email Inválido";
      } else if (error.numeric && error.numeric.$invalid) {
        text = "Ingrese solo números";
      } else if (error.minLength && error.minLength.$invalid) {
        text = `El campo debe tener mínimo ${error.minLength.$params.min} dígitos.`;
      } else if (error.maxLength && error.maxLength.$invalid) {
        text = `El campo debe tener máximo ${error.maxLength.$params.max} dígitos.`;
      } else if (error.sameAsPassword && error.sameAsPassword.$invalid) {
        text = "Las contraseñas no coinciden";
      }

      return text;
    },
  },
};
