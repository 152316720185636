<template>
  <section class="bg-agrogo-green-2">
    <!-- SECCION NUMERACION -->
    <div
      class="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-1 bg-agrogo-yellow-2 max-h-88 md:max-h-103"
    >
      <div class="flex flex-col items-center md:flex-row md:pl-20 py-2 md:py-0">
        <a
          class="font-nexa font-black text-5xl md:text-8xl text-agrogo-yellow-2 md:mr-44"
          >02</a
        >
        <div class="flex items-center space-x-4 md:space-x-5 lg:space-x-6">
          <span class="icon-punto text-white md:text-2xl"></span>
          <span class="icon-punto text-agrogo-green-3 md:text-2xl"></span>
          <span class="icon-punto text-white md:text-2xl"></span>
        </div>
      </div>
    </div>
    <section
      v-if="isBusy"
      class="containerGifLoading w-80 md:w-full max-h-88 md:max-h-245"
    >
      <img
        src="@/assets/images/loaderagrogo.gif"
        class="sizeGifLoading"
        alt=""
      />
    </section>
    <div
      v-if="!isBusy"
      class="bg-agrogo-modal w-full pt-4 md:pt-8 px-4 h-auto grid grid-cols-1 md:grid-cols-2 md:mb-24 md:px-8"
    >
      <div>
        <div
          class="md:min-h-64 md:bg-agrogo-green-4-5 mb-5 flex items-center md:pl-3 md:mr-1 md:mb-2"
        >
          <p
            class="font-nexa font-bold text-white text-left text-sm lg:text-2xl"
          >
            <span class="icon-flower leading-3 pr-1.5"></span>
            Tipo de Finca
          </p>
        </div>
        <div
          class="grid grid-cols-3 md:grid-cols-3 gap-y-4 lg:place-items-start mb-3 md:bg-agrogo-green-5 md:mr-1 lg:pl-5 lg:pr-5 sm:py-4"
        >
          <div
            v-for="(item, index) in category.subcategories"
            :key="index"
            class="flex flex-col items-center lg:flex-row lg:space-x-2"
          >
            <div
              class="card w-12 h-12 rounded-md px-0 place-content-center"
              :id="'card' + item.id"
              @click.prevent="addRemove(item)"
            >
              <span
                class="text-xl text-agrogo-gray-1"
                :class="item.icon"
              ></span>
            </div>
            <a class="font-nexa text-agrogo-gray-1 text-xs pt-1">{{
              item.name
            }}</a>
          </div>
        </div>
      </div>
      <div>
        <div
          class="md:min-h-64 md:bg-agrogo-green-4-5 mb-5 flex pr-16 md:pl-5 md:ml-1 md:mb-2"
        >
          <span
            class="icon-clock text-white text-top text-sm md:text-2xl leading-3 pr-2 pt-1 md:pt-0"
          ></span>
          <p
            class="font-nexa font-bold text-white text-left text-sm lg:text-2xl"
          >
            ¿Cuándo desearía hacer su inversión?
          </p>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 pl-11 items-start space-y-2 pb-2 md:bg-agrogo-green-5 md:ml-1 md:py-5"
        >
          <div
            class="flex space-x-2 items-center md:space-x-3"
            v-for="(item, index) in investments"
            :key="index"
          >
            <input
              checked
              type="radio"
              class="custom-radio-test cursor-pointer"
              :id="'investment' + index"
              name="investment"
              v-if="formData.investment == item.id"
            />
            <input
              v-else
              type="radio"
              class="custom-radio-test cursor-pointer"
              :id="'investment' + index"
              name="investment"
              @click="formData.investment = item.id"
            />

            <label
              :for="'investment' + index"
              class="font-dinpro font-regular text-sm text-white md:text-lg hover:text-agrogo-yellow-1 focus:text-agrogo-yellow-1 cursor-pointer"
            >
              {{ item.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- CONTENEDOR BOTONES DE NAVEGACION -->
    <div
      v-if="!isBusy"
      class="flex items-center justify-center bg-agrogo-black-2 space-x-4 md:space-x-4 lg:space-x-4 pb-14 pt-5 md:bg-agrogo-green-2 md:pb-7"
    >
      <button
        @click.prevent="() => $emit('previus', 'p2')"
        type="button"
        class="btn-nav-leads"
      >
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-xs mr-1 leading-none md:mr-2 md:text-sm"
        ></span>
        Anterior
      </button>
      <button
        @click="saveDataLead()"
        :disabled="isBusy || $v.$invalid"
        type="button"
        class="btn-nav-leads"
      >
        Siguiente
        <span
          class="icon-desplegar flex items-center transform -rotate-90 text-xs ml-1 leading-none md:ml-2 md:text-sm"
        ></span>
      </button>
    </div>
  </section>
</template>

<script>
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import { generateHash } from "@/utils";

export default {
  inject: ["provider"],
  data() {
    return {
      $v: useVuelidate(),
      investments: null,
      /**
       * saves the list of categories consulted to the agrogo api
       * @type {Object}
       */
      category: {},
      /**
       *Allows access to all the methods that establish the connection to Axios for requests to the agrogo api
       * @type {Object}
       */
      repository: this.provider.leadRepository,
      /**
       * Turn loading gif on or off
       * @type {Boolean}
       */
      isBusy: false,
      /**
       * Save the form data
       * @type {Object}
       */
      formData: {
        investment: null,
        subcategories: [],
      },
    };
  },
  validations() {
    return {
      formData: {
        subcategories: {
          required,
        },
        investment: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapState("leadSteps", ["leadStep2"]),
  },
  methods: {
    ...mapActions("leadSteps", ["actionLeadStep2"]),
    /**
     * adds the corresponding style to the selected element
     * @param {number} id element identification number
     * @returns {void}
     */
    addRemove(item) {
      const me = this;
      const cardId = document.getElementById("card" + item.id);
      if (cardId) {
        if (cardId.classList[6]) {
          cardId.classList.remove("active-card");
          me.formData.subcategories.forEach((el, index) => {
            if (el.id == item.id) me.formData.subcategories.splice(index, 1);
          });
        } else {
          me.formData.subcategories.push(item);
          cardId.classList.add("active-card");
        }
      }
    },
    // loadSelected() {
    //   const me = this;
    //   me.selected = me.list_subcategories;

    //   me.selected.forEach((el) => {
    //     const cardId = document.getElementById("card" + el);
    //     cardId.classList.add("active-card");
    //   });
    // },
    /**
     * consult the list of categories consulting the agrogo api
     * @returns {void}
     */
    async loadCategories() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();

        const {
          data: { data },
        } = await me.repository.getAll("1", dataAditional);
        me.category = data;
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    /**
     * consult the list of loadInvestments consulting the agrogo api
     * @returns {void}
     */
    async loadInvestments() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();

        const {
          data: { data },
        } = await me.repository.getAllInvestments(dataAditional);
        me.investments = data;
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    /**
     * add the styles of the categories obtained by the state leadStep2
     * @returns {void}
     */
    addClassList() {
      const me = this;
      me.formData.subcategories.forEach((el) => {
        const cardId = document.getElementById("card" + el.id);
        if (cardId) {
          cardId.classList.add("active-card");
        }
      });
    },
    /**
     * save the form information in the state leadStep2 using the action
     * @returns {void}
     */
    saveDataLead() {
      const me = this;
      me.actionLeadStep2(me.formData);
      me.$emit("next", "n2");
    },
    /**
     * retrieves the information from the state leadStep2 to assign it to the form
     * @returns {void}
     */
    getDataLead() {
      const me = this;
      if (me.leadStep2 != null) {
        me.formData = me.leadStep2;
        me.addClassList();
      }
    },
  },
  async mounted() {
    const me = this;
    await me.loadCategories();
    await me.loadInvestments();
    await me.getDataLead();
    // me.loadSelected();
  },
};
</script>

<style></style>
