<template>
  <div class="sticky top-btn z-10">
    <router-link
      class="btn-add flex items-center justify-center absolute right-6 -top-16"
      :to="
        isAuthenticated
          ? { name: 'publicar' }
          : { name: 'Login', query: { redirect: 'publicar' } }
      "
    >
      <span class="icon-add text-xl"></span>
      <p class="font-nexa font-bold">Vender</p>
    </router-link>
    <!-- <button class="btn-add flex items-center justify-center">
      <span class="icon-add text-lg"></span>
      <p class="font-nexa font-bold">Vender</p>
    </button> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("user", ["isAuthenticated"]),
  },
};
</script>

<style></style>
