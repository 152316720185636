import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
import { mapActions } from "vuex";
import alertMessageMixin from "./alertMessageMixin";

export default {
  mixins: [alertMessageMixin],
  data() {
    return {
      img: null,
      selected: null,
      errors: [],
      messageEmailPhone: null,
    };
  },
  watch: {
    selected(id) {
      const me = this;
      me.formData.code_area_id = id;
      me.loadImg(me.formData.code_area_id);
    },
  },
  methods: {
    ...mapActions("user", ["loadAuthenticated"]),
    async saveData() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAdicional = await generateHash();
        me.formData.identy_document = me.formData.identy_document + "";
        await me.repository.register({
          ...me.formData,
          ...dataAdicional,
          dev0: process.env.VUE_APP_DEBUG_CUSTOM,
        });
        // me.$router.push("/registro-exitoso");
      } catch (error) {
        const code = error.response.status;
        me.messageEmailPhone = null;
        me.errors = [];

        const data = error.response.data;
        if (data.msg) {
          me.messageEmailPhone = data;
          Object.values(data.msg).forEach((el) => {
            me.errors.push({ name: el[0] });
          });
        }

        if (code == 423) {
          const { message } = error.response.data;
          me.alertMenssage(message, 3000);
          me.$router.push({
            name: "VerifyCellPhone",
            query: { telephone: me.formData.telephone },
          });
        }
      } finally {
        me.isBusy = false;
      }
    },
    async loadCodeArea() {
      const me = this;
      try {
        const dataAdicional = await generateHash();
        const { data } = await me.repository.getAll(dataAdicional);

        data.data.forEach((el) => {
          el.phone_code = `+${el.phone_code}`;
          el.path_img_iso_medium = `${API_ROUTES.image.get}${el.path_img_iso_medium}`;
          el.path_img_iso_large = `${API_ROUTES.image.get}${el.path_img_iso_large}`;
        });
        me.codes = data.data;
        me.formData.code_area_id = 44;
        me.selected = 44;
        me.loadImg(me.formData.code_area_id);
      } catch (error) {
        // console.log(error);
      }
    },
    loadImg(id) {
      const me = this;
      const code = me.codes.find((el) => el.id == id);
      me.img = code.path_img_iso_large;
    },
  },
};
