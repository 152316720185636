import { createRouter, createWebHistory } from "vue-router";
import configRoutes from "@/config/farmRoutes";
import Home from "@/views/farm/home/HomeSearch";
import store from "../store";

const routes = [
  // RUTAS GENERALES
  {
    path: "/",
    component: Home,
  },
  {
    path: "/inicio",
    name: "HomeFarm",
    component: Home,
  },
  {
    path: "/inicio-de-sesion",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/auth/Login"),
  },
  {
    path: "/registro",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "Register" */ "@/views/auth/Register"),
  },
  {
    path: "/verificar-numero-de-telefono",
    name: "VerifyCellPhone",
    component: () =>
      import(
        /* webpackChunkName: "VerifyCellPhone" */ "@/views/auth/VerifyCellPhone"
      ),
  },
  {
    path: "/registro-exitoso",
    name: "SuccessfulRegistration",
    component: () =>
      import(
        /* webpackChunkName: "SuccessfulRegistration" */ "@/views/auth/SuccessfulRegistration"
      ),
  },
  {
    path: "/recuperacion-de-contrasena",
    name: "PasswordRecovery",
    component: () =>
      import(
        /* webpackChunkName: "PasswordRecovery" */ "@/views/auth/PasswordRecovery"
      ),
  },
  {
    path: "/cambiar-de-contrasena",
    name: "PasswordRecovery2",
    component: () =>
      import(
        /* webpackChunkName: "PasswordRecovery" */ "@/views/auth/PasswordRecovery"
      ),
  },
  {
    path: "/mi-cuenta",
    name: "mi-cuenta",
    component: () =>
      import(/* webpackChunkName: "mi-cuenta" */ "@/views/profile/MyAccount"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-cuenta-persona-natural",
    name: "MyAccountNaturalPerson",
    component: () =>
      import(
        /* webpackChunkName: "miCuenta" */ "@/views/profile/MyAccountNaturalPerson"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mi-cuenta-persona-juridica",
    name: "MyAccountLegalPerson",
    component: () =>
      import(
        /* webpackChunkName: "miCuenta" */ "@/views/profile/MyAccountLegalPerson"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Fincas
  {
    path: "/publicar-finca",
    name: "publicar",
    component: () =>
      import(
        /* webpackChunkName: "publicar" */ "@/views/farm/publishFarms/PublishFarm"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/completar-finca/:slug",
    name: "completr-publicar",
    component: () =>
      import(
        /* webpackChunkName: "completr-publicar" */ "@/views/farm/publishFarms/PublishFarm"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mis-fincas",
    name: "MyFarms",
    component: () =>
      import(/* webpackChunkName: "MyFarms" */ "@/views/farm/myFarms/MyFarms"),
    meta: {
      requiresAuth: true,
    },
  },
  // Rutas footer
  {
    path: "/quienes-somos",
    name: "AboutUs",
    component: () =>
      import(/* webpackChunkName: "AboutUs" */ "@/views/aboutUs/AboutUs"),
  },
  {
    path: "/contactanos",
    name: "ContactUs",
    component: () =>
      import(/* webpackChunkName: "ContactUs" */ "@/views/contactUs/ContactUs"),
  },
  {
    path: "/politicas-de-privacidad",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicy" */ "@/views/privacyPolicy/PrivacyPolicy"
      ),
  },
  {
    path: "/preguntas-frecuentes",
    name: "FrequentlyAskedQuestions",
    component: () =>
      import(
        /* webpackChunkName: "FrequentlyAskedQuestion" */ "@/views/frequentlyAskedQuestions/FrequentlyAskedQuestions"
      ),
  },
  {
    path: "/politicas-de-tratamiento-de-datos-personales",
    name: "personalAuthorization",
    component: () =>
      import(
        /* webpackChunkName: "personalAuthorization" */ "@/views/personalAuthorization/PersonalAuthorization"
      ),
  },
  {
    path: "/terminos-y-condiciones",
    name: "termCondition",
    component: () =>
      import(
        /* webpackChunkName: "termCondition" */ "@/views/termCondition/TermCondition"
      ),
  },
  //Error
  {
    path: "/:pathMatch(.*)",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error"*/ "@/views/error404/Error404"),
  },
  //Landing
  {
    path: "/landing",
    name: "landing",
    component: () =>
      import(/* webpackChunkName: "landing"*/ "@/components/landing/Landing"),
  },
];

if (!store.state.preLaunch.isPreLaunch) {
  let routes2 = null;
  routes2 = configRoutes["launch"].routes;
  routes2.forEach((route) => {
    routes.push(route);
  });
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export async function beforeEach(to, from, next) {
  if (!store.state.preLaunch.isPreLaunch) {
    let routes2 = null;
    routes2 = configRoutes["launch"].routes;
    routes2.forEach((route) => {
      routes.push(route);
    });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token")) {
      next();
      return;
    }
    next("/inicio-de-sesion");
  } else {
    if (
      localStorage.getItem("authenticated") === "true" &&
      to.name === "Login"
    ) {
      const isPre = localStorage.getItem("isPreLaunch");
      const path = isPre == "true" ? "/publicar-finca" : "/inicio";
      next(path);
    } else {
      next();
    }
  }
}
router.beforeEach((to, from, next) => beforeEach(to, from, next));

export default router;
