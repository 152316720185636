export default {
  namespaced: true,

  state: {
    category: [],
  },

  mutations: {
    setFarmType(state, payload) {
      state.category = payload;
    },
  },

  actions: {
    loadFarmTypes({ commit }, payload) {
      commit("setFarmType", payload);
    },
  },
};
