const API_URL = process.env.VUE_APP_URL_API_BACKEND;
const AWS_URL = process.env.VUE_APP_AWS_URL;
const API_VERSION = process.env.VUE_APP_URL_API_VERSION;

const API_ROUTES = {
  auth: {
    login: `${API_URL}/api/login`,
    register: `${API_URL}/api/register`,
    logout: `${API_URL}/api/logout`,
  },
  timezone: {
    get: `${API_URL}/api/timezone`,
  },
  codeArea: {
    get: `${API_URL}/api/${API_VERSION}/code_areas`,
  },
  image: {
    get: `${AWS_URL}`,
  },
  video: {
    get: `${API_URL}/api/${API_VERSION}/modals`,
  },
  departments: {
    get: `${API_URL}/api/${API_VERSION}/departaments`,
  },
  departmentsSiigo: {
    get: `${API_URL}/api/${API_VERSION}/departaments/siigo`,
  },
  municipalities: {
    get: `${API_URL}/api/${API_VERSION}/departaments`,
  },
  category: {
    get: `${API_URL}/api/${API_VERSION}/category`,
  },
  categoryEdit: {
    save: `${API_URL}/api/${API_VERSION}/category/search`,
  },
  stepGroup: {
    save: `${API_URL}/api/${API_VERSION}/step-group`,
  },
  stepGroupEdit: {
    save: `${API_URL}/api/${API_VERSION}/step-group/search`,
  },
  stepAttributes: {
    save: `${API_URL}/api/${API_VERSION}/steps/attributes`,
  },
  stepAttributesEdit: {
    save: `${API_URL}/api/${API_VERSION}/steps/attributes/search`,
  },
  multimediaEdit: {
    save: `${API_URL}/api/${API_VERSION}/properties/search/multimedia`,
  },
  priority: {
    get: `${API_URL}/api/${API_VERSION}/priorities`,
  },
  investment: {
    get: `${API_URL}/api/${API_VERSION}/leads/investment`,
  },
  publishFarm: {
    save: `${API_URL}/api/${API_VERSION}/properties`,
    find: `${API_URL}/api/${API_VERSION}/properties`,
    update: `${API_URL}/api/${API_VERSION}/properties`,
    img: `${API_URL}/api/${API_VERSION}/properties/update-img`,
    edit: `${API_URL}/api/${API_VERSION}/properties/edit`,
  },
  farmPreveiw: {
    find: `${API_URL}/api/${API_VERSION}/properties/preview`,
  },
  pageName: {
    get: `${API_URL}/api/${API_VERSION}/page`,
  },
  farmList: {
    get: `${API_URL}/api/${API_VERSION}/profiles/my-posts`,
  },
  favorite: {
    get: `${API_URL}/api/${API_VERSION}/favorites`,
    save_delete: `${API_URL}/api/${API_VERSION}/favorites/add`,
  },
  transaction: {
    save: `${API_URL}/api/${API_VERSION}/transaction`,
    update: `${API_URL}/api/${API_VERSION}/transaction`,
  },
  profile: {
    get: `${API_URL}/api/${API_VERSION}/profiles`,
  },
  similaryFarm: {
    get: `${API_URL}/api/${API_VERSION}/properties/show/similary`,
  },
  mainOptionNameId: {
    get: `${API_URL}/api/${API_VERSION}/main-option`,
  },
  footer: {
    get: `${API_URL}/api/${API_VERSION}/footer`,
  },
  passwordRecovery: {
    save: `${API_URL}/api/recovery-password`,
    update: `${API_URL}/api/recovery-password`,
  },
  changePassword: {
    save: `${API_URL}/api/change-password`,
  },
  lead: {
    save: `${API_URL}/api/${API_VERSION}/leads/store/leads`,
  },
  listDetailAttributes: {
    save: `${API_URL}/api/${API_VERSION}/filter/list-detail-attributes`,
  },
  getRol: {
    post: `${API_URL}/api/me`,
  },
  verifyIdentification: {
    save: `${API_URL}/api/${API_VERSION}/identy-check`,
  },
  verifyTelephone: {
    save: `${API_URL}/api/send-new-code`,
    validateCode: `${API_URL}/api/validate-account`,
  },
  saleFarm: {
    save: `${API_URL}/api/${API_VERSION}/properties/update/state`,
  },
};
export { API_ROUTES };
