<template>
  <div class="sticky position-menu z-50 menu-header" id="myPanel">
    <div
      class="bg-agrogo-green-2 md:bg-agrogo-green-1 absolute z-10 xl:left-10 container-menu-burger myPanel"
    >
      <div class="flex justify-between md:justify-none pt-5">
        <span
          class="font-nexa font-black text-white text-base md:text-2xl text-left ml-6 sm:ml-16"
          >Tipo de <span class="font-normal">Finca</span></span
        >
        <button
          class="btn-circle-Menu block mr-8 md:mr-12"
          @click.prevent="() => $emit('close')"
        >
          <span class="icon-x text-2xs md:text-sm"></span>
        </button>
      </div>
      <div
        class="grid grid-cols-2 gap-1 md:grid-cols-3 md:gap-3 mt-3 md:mt-8 myPanel"
      >
        <div
          v-for="(item, index) in category.subcategories"
          :key="index"
          @click.prevent="() => $emit('close')"
        >
          <router-link
            class="card-carousel h-16 overflow-hidden shadow-none md:h-24 grid grid-cols-3 md:grid-cols-2 myPanel text-white hover:text-agrogo-yellow-1"
            :to="{
              name: 'ListOfFarms',
              query: {
                buscar: `${item.name
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase()}-${item.id}`,
              },
            }"
          >
            <div
              class="text-3xl md:text-5xl myPanel w-full flex items-center justify-center text-agrogo-yellow-1"
            >
              <span :class="item.icon" class="myPanel"></span>
            </div>
            <div
              class="flex col-span-2 md:col-span-1 items-center font-nexa font-normal text-base md:text-lg myPanel w-full text-left"
            >
              {{ item.name }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Menu",
  computed: {
    ...mapState("farmTypes", ["category"]),
  },
  methods: {
    searchFarm(item) {
      const me = this;
      me.$router.push({
        name: "ListOfFarms",
        query: {
          buscar: `${item.name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()}-${item.id}`,
        },
      });
      me.$emit("close");
    },
  },
};
</script>

<style lang="css">
.menu-header {
  animation: fadeIn 0.8s;
  -webkit-animation: fadeIn 0.8s;
  -moz-animation: fadeIn 0.8s;
  -o-animation: fadeIn 0.8s;
  -ms-animation: fadeIn 0.8s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ml-4-5 {
  margin-left: 8px;
}
@media (min-width: 780px) {
  .ml-4-5 {
    margin-left: 18px;
  }
}
.btn-circle-Menu {
  width: 26.67px;
  height: 26.68px;
  border-radius: 50%;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff !important;
  color: #ffffff;
}
.btn-circle-Menu:hover {
  border: 1px solid #f5ce3e !important;
  color: #f5ce3e;
}

@media (min-width: 767px) {
  .btn-circle-Menu {
    width: 32px;
    height: 32px;
  }
}
.position-menu {
  top: 89px;
}
@media (min-width: 767px) {
  .position-menu {
    top: 64px;
  }
}
</style>
