<template>
  <Transition name="slide-fade">
    <div
      id="modal-video"
      class="fixed z-50 inset-0 overflow-y-auto modal-video"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      v-if="isModal"
    >
      <div
        class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          @click.prevent="() => $emit('closeModal')"
        ></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <Transition name="slide-fade">
          <div
            class="relative bg-transparent inline-block align-bottom shadow-xl transform transition-all sm:align-middle content-frame-video"
            v-if="isModal"
          >
            <div class="flex justify-end content-button-ModalVideo">
              <button
                type="Button"
                class="btn-circle-ModalVideo absolute"
                @click.prevent="() => $emit('closeModal')"
              >
                <span class="icon-x text-agrogo-gray-1"></span>
              </button>
            </div>
            <div class="" v-if="url">
              <iframe
                v-if="url.video == 1"
                class="frame-video rounded-2xl"
                :src="lodaUrl(url.url_media)"
                title=""
              >
              </iframe>
              <img
                v-else
                :src="url?.urlImg"
                class="w-full h-auto rounded-2xl"
                alt=""
              />
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    url: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // isModal: true,
    };
  },
  methods: {
    lodaUrl(url) {
      return url;
    },
  },
};
</script>
