export default {
  namespaced: true,

  state: {
    isAuthenticated: localStorage.getItem("access_token") ? true : false,
  },

  mutations: {
    setAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
  },

  actions: {
    loadAuthenticated({ commit }, payload) {
      commit("setAuthenticated", payload);
    },
  },
};
