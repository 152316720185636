<template>
  <Transition name="slide-fade">
    <div
      class="fixed z-50 inset-0 overflow-y-auto w-screen"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      v-if="isModalLead"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-black-trasparent transition-opacity"
          aria-hidden="true"
        ></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <Transition name="slide-fade">
          <div
            class="relative bg-transparent inline-block align-bottom shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full md:max-w-2xl lg:max-w-4xl"
            v-if="isModal"
          >
            <div class="flex justify-end content-button-ModalLead">
              <button
                v-if="isCover"
                type="Button"
                class="btn-circle-ModalLead absolute bg-transparent"
                @click.prevent="() => $emit('closeModalLead')"
              >
                <!-- icon, (se debe definir tamaño, color y transparencia como si fuera un texto) -->
                <span class="icon-x"></span>
              </button>
              <button
                v-else
                type="Button"
                class="btn-circle-ModalLead absolute"
                @click.prevent="() => $emit('closeModalLead')"
              >
                <!-- icon, (se debe definir tamaño, color y transparencia como si fuera un texto) -->
                <span class="icon-x"></span>
              </button>
            </div>
            <lead-cover v-if="isCover" @buy="buy" @explore="explore" />
            <lead-step-1 v-else-if="isStep1" @previus="previus" @next="next" />
            <lead-step-2 v-else-if="isStep2" @previus="previus" @next="next" />
            <lead-step-3
              v-else
              @previus="previus"
              @send="send"
              :isBusy2="isBusy"
            />
          </div>
        </Transition>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapActions, mapState } from "vuex";
// components
import LeadCover from "./leads/LeadCover";
import LeadStep1 from "./leads/LeadStep1";
import LeadStep2 from "./leads/LeadStep2";
import LeadStep3 from "./leads/LeadStep3";

export default {
  inject: ["provider"],
  emits: ["closeModalLead"],
  components: {
    LeadCover,
    LeadStep1,
    LeadStep2,
    LeadStep3,
  },
  props: {
    isModalLead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const me = this;
    return {
      repository: me.provider.leadRepository,
      isModal: me.isModalLead,
      isCover: true,
      isStep1: false,
      isStep2: false,
      isStep3: false,
      isBusy: false,
    };
  },
  watch: {
    isModalLead(newData) {
      this.isModal = newData;
    },
  },
  computed: {
    ...mapState("leadSteps", ["leadStep1", "leadStep2", "leadStep3"]),
  },
  methods: {
    ...mapActions("leadSteps", ["saveCaptaction"]),
    buy() {
      const me = this;
      me.isCover = false;
      me.isStep1 = true;
    },
    explore() {
      const me = this;
      me.$emit("closeModalLead");
    },
    previus(step) {
      const me = this;
      if (step == "p1") {
        me.isCover = true;
        me.isStep1 = false;
      } else if (step == "p2") {
        me.isStep1 = true;
        me.isStep2 = false;
      } else {
        me.isStep2 = true;
        me.isStep3 = false;
      }
    },
    next(step) {
      const me = this;
      if (step == "n1") {
        me.isStep1 = false;
        me.isStep2 = true;
      } else if (step == "n2") {
        me.isStep2 = false;
        me.isStep3 = true;
      }
    },
    async send() {
      const me = this;

      const formData = { ...me.leadStep1, ...me.leadStep2, ...me.leadStep3 };

      formData.price_max = Number(
        formData.price_max.split("$")[1].split(".").join("")
      );
      formData.price_min = Number(
        formData.price_min.split("$")[1].split(".").join("")
      );

      formData.code_id = formData.code_area_id;

      me.fillQuery(formData);
      me.saveCaptaction(true);
      me.$emit("closeModalLead");
    },
    fillQuery(data) {
      const me = this;
      const querys = [];

      me.$router.push({ query: null }); //delete all filters

      // Filtro tipos de finca
      if (data.subcategories.length > 0) {
        const filters = [];
        data.subcategories.forEach((el) => {
          filters.push(
            `${el.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()}-${el.id}`
          );
        });
        querys.push({
          name: "tipos-de-fincas",
          value: filters,
        });
      }

      // Filtro departamento
      if (data.departmentId) {
        const filters = [];
        data.departmentId.forEach((el) => {
          filters.push(
            `${el.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()}-${el.id}`
          );
        });
        querys.push({
          name: "departamentos",
          value: filters,
        });
      }

      // Filtro municipios
      if (data.municipality_id.length > 0) {
        const filters = [];
        data.municipality_id.forEach((el) => {
          filters.push(`${el.name}-${el.id}`);
        });
        querys.push({
          name: "municipios",
          value: filters,
        });
      }

      // Filtro por area

      if (data.area_min) {
        querys.push({
          name: "area-desde",
          value: data.area_min,
        });
      }

      if (data.area_max) {
        querys.push({
          name: "area-hasta",
          value: data.area_max,
        });
      }

      // Filtro precio total desde - hasta

      if (data.price_min) {
        querys.push({
          name: "precio-total-desde",
          value: data.price_min,
        });
      }

      if (data.price_max) {
        querys.push({
          name: "precio-total-hasta",
          value: data.price_max,
        });
      }

      let query = null;
      querys.forEach((el) => {
        query = {
          ...query,
          [el.name]: [el.value],
        };
      });
      me.$router.push({ name: "ListOfFarms", query });
    },
  },
};
</script>

<style>
.bg-black-trasparent {
  background: rgba(0, 0, 0, 0.7);
}
</style>
