<template>
  <main>
    <router-view />
    <Footer />
  </main>
</template>

<script>
import { generateHash } from "@/utils";
import { mapState } from "vuex";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    Footer,
  },
  watch: {},
  computed: {
    ...mapState("preLaunch", ["isPreLaunch"]),
    ...mapState("preLaunch", ["isLoad"]),
  },
  methods: {
    async loadTimezone() {
      const me = this;
      try {
        await generateHash();
        if (me.isPreLaunch) {
          me.$router.push("/inicio-de-sesion");
        }
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    blockRightClick() {
      // const app = document.getElementById("app");

      window.onload = function () {
        document.addEventListener(
          "contextmenu",
          function (e) {
            e.preventDefault();
          },
          false
        );
      };

      // app.addEventListener("click", () => {
      //   alert("click izquierdo");
      // });
    },
  },
  mounted() {
    const me = this;
    me.loadTimezone();
    // me.blockRightClick();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
