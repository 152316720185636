import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("user", ["loadAuthenticated"]),
    validationExpiredToken() {
      const me = this;
      localStorage.removeItem("access_token");
      localStorage.removeItem("authenticated");
      me.loadAuthenticated(false);

      me.$swal({
        iconHtml:
          '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40.0005 6.66797L48.7555 13.0546L59.5938 13.0346L62.9222 23.348L71.7022 29.7013L68.3338 40.0013L71.7022 50.3013L62.9222 56.6546L59.5938 66.968L48.7555 66.948L40.0005 73.3346L31.2455 66.948L20.4072 66.968L17.0788 56.6546L8.29883 50.3013L11.6672 40.0013L8.29883 29.7013L17.0788 23.348L20.4072 13.0346L31.2455 13.0546L40.0005 6.66797Z" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M28.334 40.0013L36.6673 48.3346L53.334 31.668" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        customClass: {
          icon: "no-border-swal-alert",
        },
        text: "Su sesión ha caducado, debes volver a iniciar sesión",
        showConfirmButton: true,
        timer: 8000,
      });

      me.$router.push("/inicio-de-sesion");
    },
  },
};
