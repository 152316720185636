import axios from "axios";
axios.defaults.headers.common["Accept"] = "application/json";

axios.interceptors.request.use((config) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token) config.headers.Authorization = `Bearer ${access_token}`;

  return config;
});

export default axios;
