<template>
  <div class="relative flex items-center w-auto">
    <router-link :to="{ name: 'ListOfFarms', query: { oportunity: true } }">
      <button
        class="btnOpportunityFarms transform transition-all hover:scale-105"
      >
        <span class="font-dinpro font-medium"
          >Fincas de <span class="font-bold">Oportunidad</span></span
        >
        <div class="positionStarButton">
          <div class="starButton">
            <span
              class="icon-rocket text-base text-white z-20 md:text-xl md:pt-1"
            ></span>
          </div>
        </div>
      </button>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style>
.btnOpportunityFarms {
  height: 26px;
  width: 157px;
  border-radius: 8px;
  background-color: #8511fa;
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  justify-items: flex-start;
  padding-left: 9px;
  z-index: 10;
}
.starButton {
  background-image: url("../../assets/images/starpurple.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.positionStarButton {
  position: absolute;
  padding-left: 120px;
}

@media (min-width: 768px) {
  .btnOpportunityFarms {
    height: 32px;
    width: 201px;
    font-size: 14px;
    padding-left: 18.94px;
  }
  .positionButtonListOfFarms {
    top: 0px;
    right: 0px;
  }
  .positionButtonFilterFarms {
    top: 0;
    right: 0;
  }
  .starButton {
    width: 61px;
    height: 61px;
  }
  .positionStarButton {
    padding-left: 150px;
  }
}
</style>
