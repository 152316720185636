import { axios, API_ROUTES } from "@/config";

export default {
  async login(data) {
    let result = await axios.post(`${API_ROUTES.auth.login}`, data);

    return result;
  },

  async register(data) {
    let result = await axios.post(`${API_ROUTES.auth.register}`, data);

    return result;
  },

  async timezone() {
    let result = await axios.get(`${API_ROUTES.timezone.get}`);

    return result;
  },

  async getAll(params) {
    let result = await axios.get(`${API_ROUTES.codeArea.get}`, {
      params: params,
    });

    return result;
  },

  async image(path) {
    let result = await axios.get(`${API_ROUTES.image.get}${path}`);

    return result;
  },

  async delete(data) {
    let result = await axios.post(`${API_ROUTES.auth.logout}`, data);

    return result;
  },

  async passwordRecovery(data) {
    let result = await axios.post(`${API_ROUTES.passwordRecovery.save}`, data);

    return result;
  },

  async validateCode(data) {
    let result = await axios.put(`${API_ROUTES.passwordRecovery.update}`, data);

    return result;
  },

  async changePassword(data) {
    let result = await axios.post(`${API_ROUTES.changePassword.save}`, data);

    return result;
  },

  async getRol(data) {
    let result = await axios.post(`${API_ROUTES.getRol.post}`, data);
    return result;
  },
};
