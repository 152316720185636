export default {
  namespaced: true,

  state: {
    isPreLaunch: false,
    isLoad: true,
  },

  mutations: {
    setIsPreLaunch(state, payload) {
      if (state.isPreLaunch && !payload) {
        window.location.reload();
      }
      state.isPreLaunch = payload;
    },
    setIsLoad(state, payload) {
      state.isLoad = payload;
    },
  },

  actions: {
    loadLaunch({ commit }, payload) {
      commit("setIsPreLaunch", payload);
    },
    loadisLoad({ commit }, payload) {
      commit("setIsLoad", payload);
    },
  },
};
