<template>
  <ais-instant-search index-name="alg_properties" :search-client="searchClient">
    <!-- :hits-per-page.camel="10" -->
    <ais-configure>
      <ais-autocomplete>
        <template v-slot="{ currentRefinement, indices, refine }">
          <form
            class="md:flex md:flex-row"
            @submit.prevent="redirectListFarm(currentRefinement)"
          >
            <div class="input__field">
              <input
                type="text"
                :value="currentRefinement"
                class="search-bar search-algolia"
                :class="{ 'search-bar-border': currentRefinement }"
                placeholder="Buscar"
                id="search-desktop"
                @keyup="refine($event.currentTarget.value)"
              />
              <span
                v-if="currentRefinement"
                id="closeSearch"
                class="icon-x text-agrogo-yellow-1 icon_x search-algolia"
                @click.prevent="refine(($event.currentTarget.value = ''))"
              ></span>
            </div>
            <button
              type="submit"
              class="icon-search-bar search-algolia"
              :class="{ 'icon-search-bar-boder': currentRefinement }"
              @click.prevent="
                redirectListFarm(currentRefinement),
                  refine(($event.currentTarget.value = ''))
              "
            ></button>
          </form>
          <div id="list-search" v-if="currentRefinement">
            <div v-for="index in indices" :key="index.indexId">
              <div
                class="md:flex md:flex-row bg-white px-1 pb-1 space-y-2 flex-wrap"
              >
                <div class="container-search-algolia-desktop bg-agrogo-green-2">
                  <div
                    class="flex items-center justify-center h-9 bg-agrogo-gray-1"
                  >
                    <p
                      class="text-xs md:text-sm lg:text-base font-dinpro font-medium text-agrogo-gray-3"
                    >
                      Se han encontrado
                      {{ index.hits.length }} resultados
                    </p>
                  </div>
                  <div class="search-home-style">
                    <div
                      v-for="item in index.hits"
                      :key="item.objectID"
                      class="bg-agrogo-green-2 cursor-pointer"
                      @click.prevent="
                        sendDetail(item),
                          refine(($event.currentTarget.value = ''))
                      "
                    >
                      <div class="bg-agrogo-green-2">
                        <div
                          :id="'style-search-home' + item.id"
                          class="container-search-home"
                        >
                          <div class="flex px-4 lg:px-6 xl:px-8 py-2 gap-x-1.5">
                            <div class="h-auto flex items-center">
                              <div class="flex flex-col space-y-2">
                                <p
                                  class="text-xs md:text-sm lg:text-lg text-left font-nexa font-normal"
                                >
                                  {{ item.name }}
                                </p>
                                <!-- <p
                                  class="text-xs md:text-base text-left font-nexa"
                                >
                                  {{ item.municipality_id.name }},
                                  <b>{{ item.department_id.name }}</b>
                                </p> -->
                                <div
                                  class="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 text-center"
                                >
                                  <span
                                    v-for="category in item.alg_categories.slice(
                                      0,
                                      3
                                    )"
                                    :key="category.id"
                                    class="text-agrogo-yellow-1 text-lg xl:text-2xl"
                                    :class="category.icon_subcategory"
                                  ></span>
                                  <span
                                    v-if="item.alg_categories.length > 3"
                                    class="text-agrogo-yellow-1 text-2xl"
                                  >
                                    ...
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="flex flex-col justify-between">
                              <p
                                class="flex items-start justify-end text-sm lg:text-base font-dinpro font-normal opacity-80"
                              >
                                <span>ID#</span>
                                {{ item.property_id }}
                              </p>
                              <div class="flex flex-col items-end justify-end">
                                <p
                                  class="flex text-lg lg:text-2xl font-dinpro font-normal opacity-80"
                                >
                                  <span
                                    class="icon-signo-pesos text-sm lg:text-xl mr-1 mt-1 opacity-100"
                                  ></span>
                                  {{ formatThousand(item.price) }}
                                </p>
                                <p
                                  class="font-dinpro font-normal text-xs text-agrogo-gray-1"
                                >
                                  (Pesos Colombianos)
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ais-autocomplete>
    </ais-configure>
  </ais-instant-search>
</template>

<script>
// Algolia
import algoliasearch from "algoliasearch/lite";

export default {
  data() {
    const algoliaId =
      process.env.VUE_APP_DEBUG == "true"
        ? process.env.VUE_APP_ALGOLIA_ID_TEST
        : process.env.VUE_APP_ALGOLIA_ID;
    const algoliaPrivateKey =
      process.env.VUE_APP_DEBUG == "true"
        ? process.env.VUE_APP_PRIVATE_KEY_ALGOLIA_TEST
        : process.env.VUE_APP_PRIVATE_KEY_ALGOLIA;
    return {
      searchClient: algoliasearch(algoliaId, algoliaPrivateKey),
    };
  },
  methods: {
    formatThousand(number) {
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        currency: "COP",
        minimumFractionDigits: 0,
      });
      number = formatterPeso.format(number);
      return number;
    },
    sendDetail(item) {
      const me = this;
      // const searchAlgolia = document.getElementById("searchAlgolia");
      // console.log(searchAlgolia);
      me.$router.push({ name: "FarmDetail", params: { slug: item.slug } });
    },
    redirectListFarm(text) {
      const me = this;
      if (text) {
        me.$router.push({
          name: "ListOfFarms",
          query: { buscar: text },
        });
      }
      const search = document.getElementById("search-desktop");
      search.blur();
    },
  },
};
</script>

<style lang="css">
.input__field {
  width: 100%;
  display: flex;
  position: relative;
}
.icon_x {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  left: unset;
  right: 12px;
  cursor: pointer;
}
</style>
