<template>
  <div>
    <footer
      class="bg-agrogo-green-4 container-footer md:flex md:flex-col md:justify-end lg:flex lg:flex-col lg:justify-end"
    >
      <div class="bg-agrogo-green-3 py-1 md:hidden lg:hidden"></div>
      <div class="bg-agrogo-footer">
        <div class="flex justify-center mt-8 md:mt-11 space-x-2.5">
          <a
            v-if="!isPreLaunch"
            class=""
            :href="urlGooglePlayStore"
            target="_blank"
            ><img
              v-if="imgGooglePlayStore"
              class="w-auto h-8 md:h-10"
              :src="loadImg(imgGooglePlayStore)"
              alt=""
          /></a>
          <a v-if="!isPreLaunch" class="" :href="urlAppStore" target="_blank"
            ><img
              v-if="imgAppStore"
              class="w-auto h-8 md:h-10"
              :src="loadImg(imgAppStore)"
              alt=""
          /></a>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 mt-12 md:mt-14">
          <div
            class="flex justify-center items-center space-x-6 md:justify-end md:pr-4"
            v-if="!isPreLaunch"
          >
            <a :href="urlFB" target="_blank" class="flex items-center">
              <span
                class="text-agrogo-yellow-1 text-2xl hover:text-white"
                :class="iconFB"
              ></span>
            </a>
            <a :href="urlIG" target="_blank" class="flex items-center pt-0.5">
              <span
                class="text-agrogo-yellow-1 text-2xl hover:text-white"
                :class="iconIG"
              ></span>
            </a>
            <a :href="urlIN" target="_blank" class="flex items-center">
              <span
                class="text-agrogo-yellow-1 text-2xl hover:text-white"
                :class="iconIN"
              ></span>
            </a>
          </div>
          <div
            class="flex justify-center items-center pt-4 md:justify-start md:py-0"
            v-if="!isPreLaunch"
          >
            <span class="text-white text-sm font-nexa font-black md:text-base"
              >AGROGO.com.co</span
            >
          </div>
        </div>
        <div
          class="flex flex-col md:flex-row items-center justify-center space-y-3.5 pt-8 md:pt-12 pb-9 md:pb-12 md:space-x-4 md:space-y-0"
        >
          <div
            class="text-agrogo-yellow-1 text-sm font-nexa font-normal hover:text-white md:text-lg"
            v-for="item in dataMain"
            :key="item"
          >
            <router-link v-if="!isPreLaunch" :to="item.url">
              {{ item.name }}
            </router-link>
          </div>
        </div>
      </div>
      <div
        class="flex w-full bg-agrogo-yellow-1 min-h-60 items-center justify-start lg:justify-center relative"
      >
        <div class="flex text-left lg:text-center pl-6 lg:pl-0">
          <p
            class="text-agrogo-green-2 text-xs lg:text-xl font-dinpro font-normal"
          >
            Todos los derechos reservados.
            <br class="lg:hidden" /><span class="font-nexa font-black"
              >AGRO GO</span
            >
            Colombia. 2023
          </p>
        </div>
        <div class="flex space-x-4 pr-6 lg:space-x-6 lg:pr-14 absolute right-0">
          <a class="flex" href="https://furore.co/inicio/" target="_blank">
            <img
              class="w-11 lg:w-16"
              srcset="@/assets/images/imagesFooter/logofurore.svg"
              src="@/assets/images/imagesFooter/logofurore@2x.png"
              alt=""
            />
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
import { mapState } from "vuex";

export default {
  name: "Footer",
  inject: ["provider"],
  data() {
    return {
      repository: this.provider.footerRepository,
      dataFooter: "",
      dataMain: "",
      imgGooglePlayStore: null,
      urlGooglePlayStore: null,
      imgAppStore: null,
      urlAppStore: null,
      iconFB: null,
      urlFB: null,
      iconIG: null,
      urlIG: null,
      iconIN: null,
      urlIN: null,
    };
  },
  computed: {
    ...mapState("preLaunch", ["isPreLaunch"]),
  },
  methods: {
    /**
     * consult the api of footer to obtain the data
     * @returns  { void }
     */
    async getMainOption() {
      try {
        const me = this;
        const dataAditional = await generateHash();
        const { data } = await me.repository.getAll(dataAditional);
        me.dataFooter = data.configuration;
        me.dataMain = data.main.items;
        me.dataFooter.forEach((el) => {
          if (el.id_name == "GOOGLE_PLAY_IMG") {
            me.imgGooglePlayStore = el.value;
          } else if (el.id_name == "GOOGLE_PLAY") {
            me.urlGooglePlayStore = el.value;
          } else if (el.id_name == "APP_STORE_IMG") {
            me.imgAppStore = el.value;
          } else if (el.id_name == "APP_STORE") {
            me.urlAppStore = el.value;
          } else if (el.id_name == "ICON_IG") {
            me.iconIG = el.value;
          } else if (el.id_name == "URL_INSTAGRAM") {
            me.urlIG = el.value;
          } else if (el.id_name == "ICON_FB") {
            me.iconFB = el.value;
          } else if (el.id_name == "URL_FACEBOOK") {
            me.urlFB = el.value;
          } else if (el.id_name == "ICON_IN") {
            me.iconIN = el.value;
          } else if (el.id_name == "URL_LINKEDIN") {
            me.urlIN = el.value;
          }
        });
      } catch (error) {
        // console.log(error);
      }
    },
    loadImg(path) {
      const url = `${API_ROUTES.image.get}${path}`;
      return url;
    },
  },
  mounted() {
    const me = this;
    me.getMainOption();
  },
};
</script>

<style lang="css" scoped>
@media (min-width: 768px) {
  .mr-4-5 {
    margin-right: 18px;
  }
}
</style>
