<template>
  <header class="bg-agrogo-green-1 px-4 space-x-2 z-40 sticky top-0">
    <div class="container-header">
      <template v-if="!isPreLaunch">
        <div
          class="myPanel md:w-52 flex items-center"
          v-if="!activeSearchDesktop"
        >
          <button
            class="text-agrogo-yellow-1 hover:text-white flex items-center myPanel"
            @click="activeMenu()"
          >
            <span class="icon-BurguerMenu text-lg lg:text-2xl myPanel"></span>
          </button>
        </div>
        <div class="myPanel flex items-center" v-else>
          <button
            class="text-agrogo-yellow-1 hover:text-white flex items-center myPanel"
            @click="activeMenu()"
          >
            <span class="icon-BurguerMenu text-lg lg:text-2xl myPanel"></span>
          </button>
        </div>
      </template>

      <router-link
        class="flex items-center"
        :to="isPreLaunch ? '/inicio-de-sesion' : '/inicio'"
        ><img
          class="max-h-7 lg:max-h-10"
          srcset="@/assets/images/imagesHeader/agrogofincas.svg"
          src="@/assets/images/imagesHeader/agrogofincas.png"
          alt="AGROGO Fincas"
      /></router-link>
      <div class="flex md:hidden items-center">
        <router-link
          :to="
            isAuthenticated
              ? { name: 'publicar' }
              : { name: 'Login', query: { redirect: 'publicar' } }
          "
        >
          <button
            class="text-agrogo-yellow-1 flex flex-col pr-2 hover:text-white items-center justify-center"
          >
            <span class="icon-Publicar text-2xl text-center"></span>
            <span class="font-nexa text-xs mt-1 text-white">Publicar</span>
          </button>
        </router-link>

        <router-link
          :to="
            isAuthenticated
              ? { name: 'mi-cuenta' }
              : { name: 'Login', query: { redirect: 'mi-cuenta' } }
          "
        >
          <button
            class="text-agrogo-yellow-1 flex flex-col pl-2 hover:text-white items-center justify-center"
          >
            <span class="icon-Perfil text-2xl"></span>
            <span class="font-nexa text-xs mt-1 text-white">
              {{ isAuthenticated ? "Mi cuenta" : "Ingresar" }}
            </span>
          </button>
        </router-link>
      </div>
      <!-- barra de busqueda escritorio-->
      <div
        class="hidden md:flex items-start pt-3 z-30"
        v-if="activeSearchDesktop && !isPreLaunch"
      >
        <search-algolia-desktop />
      </div>
      <div class="hidden md:flex items-center">
        <router-link
          :to="
            isAuthenticated
              ? { name: 'publicar' }
              : { name: 'Login', query: { redirect: 'publicar' } }
          "
        >
          <button
            class="text-agrogo-yellow-1 flex flex-row pr-2 items-center hover:text-white"
          >
            <span class="font-nexa text-base text-white pr-2">Publicar</span>
            <span class="icon-Publicar text-2xl xl:mr-4"></span>
          </button>
        </router-link>

        <router-link
          :to="
            isAuthenticated
              ? { name: 'mi-cuenta' }
              : { name: 'Login', query: { redirect: 'mi-cuenta' } }
          "
        >
          <button
            class="text-agrogo-yellow-1 flex flex-row items-center hover:text-white"
          >
            <span class="font-nexa text-base pr-2 text-white">
              {{ isAuthenticated ? "Mi cuenta" : "Ingresar" }}
            </span>
            <span class="icon-Perfil text-2xl"></span>
          </button>
        </router-link>
      </div>
    </div>
    <!-- barra de busqueda movil-->
    <div
      class="flex justify-center md:hidden pb-4"
      v-if="activeSearchMobile && !isPreLaunch"
    >
      <div class="w-full z-30"><search-algolia-mobile /></div>
    </div>
  </header>
  <Menu v-if="stateMenu" @close="stateMenu = false" />
</template>

<script>
import { mapState, mapActions } from "vuex";
import { generateHash } from "@/utils";
// components
import SearchAlgoliaDesktop from "./SearchAlgoliaDesktop";
import SearchAlgoliaMobile from "./SearchAlgoliaMobile";
import Menu from "./Menu";

export default {
  inject: ["provider"],
  name: "PxHeader",
  data() {
    return {
      /**
       * @type {Boolean}
       */
      stateMenu: false,
      /**
       * @type {provider}
       */
      repository: this.provider.leadRepository,
      /**
       * @type {Boolean}
       */
      isBusy: false,
    };
  },
  props: {
    msg: String,
    activeSearchDesktop: {
      type: Boolean,
      default: true,
    },
    activeSearchMobile: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Menu,
    SearchAlgoliaDesktop,
    SearchAlgoliaMobile,
  },
  computed: {
    ...mapState("user", ["isAuthenticated"]),
    ...mapState("preLaunch", ["isPreLaunch"]),
  },
  methods: {
    ...mapActions("farmTypes", ["loadFarmTypes"]),
    /**
     * Toggles the menu component on and off.
     * @returns  {void}
     */
    activeMenu() {
      if (this.stateMenu) {
        this.stateMenu = false;
      } else {
        this.stateMenu = true;
      }
    },
    /**
     * Deactivates the menu when pressing outside the el
     * @returns  {void}
     */
    closeMenuDocument() {
      const me = this;
      document.addEventListener("click", (e) => {
        const menu = document.getElementById("myPanel");
        if (menu) {
          if (!e.target.className.includes("myPanel")) {
            me.stateMenu = false;
          }
        }
      });
    },
    /**
     * consult the api that of categories
     * @returns  {void}
     */
    async loadCategories() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();

        const {
          data: { data },
        } = await me.repository.getAll("1", dataAditional);
        me.loadFarmTypes(data);
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    closeSearch() {
      document.addEventListener("click", (e) => {
        const listSearch = document.getElementById("list-search");
        if (listSearch) {
          if (!e.target.className.includes("search-algolia")) {
            const btn = document.getElementById("closeSearch");
            btn.click();
          }
        }
      });
    },
  },
  mounted() {
    const me = this;
    me.closeSearch();
    me.loadCategories();
    me.closeMenuDocument();
  },
};
</script>
