export default {
  namespaced: true,

  state: {
    leadStep1: null,
    leadStep2: null,
    leadStep3: null,
    isSaveCaptaction: false,
  },

  mutations: {
    setLeadStep1(state, payload) {
      state.leadStep1 = payload;
    },
    setLeadStep2(state, payload) {
      state.leadStep2 = payload;
    },
    setLeadStep3(state, payload) {
      state.leadStep3 = payload;
    },
    setSaveCaptaction(state, payload) {
      state.isSaveCaptaction = payload;
    },
  },

  actions: {
    actionLeadStep1({ commit }, payload) {
      commit("setLeadStep1", payload);
    },
    actionLeadStep2({ commit }, payload) {
      commit("setLeadStep2", payload);
    },
    actionLeadStep3({ commit }, payload) {
      commit("setLeadStep3", payload);
    },
    saveCaptaction({ commit }, payload) {
      commit("setSaveCaptaction", payload);
    },
  },

  getters: {},
};
