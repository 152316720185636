<template>
  <section class="bg-agrogo-green-2">
    <!-- SECCION NUMERACION -->
    <div
      class="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-1 bg-agrogo-yellow-2 max-h-88 md:max-h-103"
    >
      <div class="flex flex-col items-center md:flex-row md:pl-20 py-2 md:py-0">
        <a
          class="font-nexa font-black text-5xl md:text-8xl text-agrogo-yellow-2 md:mr-44"
          >01</a
        >
        <div class="flex items-center space-x-4 md:space-x-5 lg:space-x-6">
          <span class="icon-punto text-agrogo-green-3 md:text-2xl"></span>
          <span class="icon-punto text-white md:text-2xl"></span>
          <span class="icon-punto text-white md:text-2xl"></span>
        </div>
      </div>
    </div>
    <section
      v-if="isBusy"
      class="containerGifLoading w-80 md:w-full max-h-88 md:max-h-245"
    >
      <img
        src="@/assets/images/loaderagrogo.gif"
        class="sizeGifLoading"
        alt=""
      />
    </section>
    <div
      v-else
      class="bg-agrogo-modal h-full pt-4 md:pt-8 px-3 pb-5 md:px-8 md:bg-transparent"
    >
      <div class="grid grid-cols-1">
        <div
          class="flex items-center md:h-16 md:bg-agrogo-green-4-5 mb-6 md:mb-2"
        >
          <div
            class="flex items-center font-nexa font-bold text-white text-sm md:text-lg lg:text-2xl"
          >
            <span
              class="icon-ubicacion pr-1.5 text-base md:text-xl lg:text-3xl ml-3"
            ></span>
            <p class="">Ubicación</p>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-x-2 md:gap-x-0 w-full">
          <div
            class="grid grid-cols-1 lg:grid-cols-2 mb-8 md:items-center md:bg-agrogo-green-5 md:mr-1 md:px-4 md:mb-2 md:py-4"
          >
            <div class="hidden lg:flex pl-5">
              <a class="font-nexa text-white text-sm text-left md:text-lg"
                >Departamento</a
              >
            </div>
            <div class="flex justify-end items-center">
              <v-select
                :options="departments"
                v-model="formData.departmentId"
                class="style-chooser3 w-full"
                label="name"
                @update:modelValue="loadMunicipies()"
                placeholder="Departamento"
                :close-on-select="false"
                multiple
              >
                <template #no-options>Sin opciones</template>
              </v-select>
            </div>
          </div>
          <div
            class="grid grid-cols-1 lg:grid-cols-2 mb-8 md:items-center md:bg-agrogo-green-5 md:ml-1 md:px-4 md:mb-2 md:py-4"
          >
            <div class="hidden lg:flex pl-5">
              <a class="font-nexa text-white text-sm text-left md:text-lg"
                >Municipio</a
              >
            </div>
            <div class="flex justify-end items-center">
              <v-select
                :options="municipalities"
                id="municipality"
                v-model="formData.municipality_id"
                class="style-chooser3 w-full"
                label="name"
                :close-on-select="false"
                placeholder="Municipio"
                multiple
              >
                <template #no-options>Sin opciones</template>
              </v-select>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row">
        <div class="flex flex-col items-start md:w-1/2 md:mr-1">
          <div
            class="flex items-center mb-3.5 md:bg-agrogo-green-4-5 w-full md:h-16 md:px-4 md:mb-2"
          >
            <div
              class="flex items-center font-nexa font-bold text-white text-left text-sm md:text-lg lg:text-2xl"
            >
              <span class="icon-area leading-3 pr-2"></span> Área

              <div class="tooltip-container pl-2 md:pl-3">
                <i class="fas fa-question-circle tooltip-trigger text-white">
                  <span
                    class="icon-Group text-agrogo-yellow-2 text-lg md:text-2xl ml-1.5 transform transition-all"
                  ></span>
                </i>
                <div class="font-normal tooltip-lead1">
                  1 cuadra = 0.64 hectáreas
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:bg-agrogo-green-5 md:pt-5 md:pb-2">
            <div class="grid grid-cols-2 ml-6 md:mr-4 pb-3 md:pb-0 xl:gap-x-2">
              <div class="flex items-center">
                <a class="font-nexa text-white text-sm text-left md:text-lg"
                  >Hectáreas</a
                >
              </div>
              <div class="grid grid-cols-2 gap-x-2">
                <div class="flex flex-col justify-center">
                  <p
                    class="font-dinpro font-normal text-sm text-white text-center mb-1"
                  >
                    Desde
                  </p>
                  <input
                    id="area_min"
                    name="area_min"
                    type="text"
                    v-model="formData.area_min"
                    @input="formatArea1"
                    @blur="validationArea1"
                    class="input-standard-transparent"
                  />
                </div>
                <div class="flex flex-col justify-center">
                  <p
                    class="font-dinpro font-normal text-sm text-white text-center mb-1"
                  >
                    Hasta
                  </p>
                  <input
                    id="area_max"
                    name="area_max"
                    type="text"
                    v-model="formData.area_max"
                    @input="formatArea2"
                    @blur="validationArea2"
                    class="input-standard-transparent"
                  />
                </div>
              </div>
            </div>
            <p
              class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
              v-if="isValidationArea1"
            >
              El area Desde no debe ser superior al Hasta.
            </p>
            <p
              class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
              v-else-if="isValidationArea2"
            >
              El area Hasta no debe ser inferior al Desde.
            </p>
            <p
              class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
              v-else
            >
              &nbsp;
            </p>
          </div>
          <div class="w-full md:bg-agrogo-green-5">
            <div class="grid grid-cols-2 xl:gap-x-2 ml-6 md:mr-4 pb-8 md:pb-3">
              <div class="flex items-center">
                <p
                  class="text-white text-sm lg:text-base xl:text-lg font-nexa text-left"
                >
                  Fanegadas, <br class="md:hidden" />
                  Plazas, <br />
                  Cuadras
                </p>
              </div>
              <div class="grid grid-cols-2 gap-x-2">
                <div class="flex items-start justify-center">
                  <input
                    type="text"
                    :value="hectaresBlocks(formData.area_min)"
                    disabled
                    class="input-standard w-full text-white bg-agrogo-gray-1-opacity"
                  />
                </div>
                <div class="flex items-start justify-center">
                  <input
                    type="text"
                    :value="hectaresBlocks(formData.area_max)"
                    disabled
                    class="input-standard w-full text-white bg-agrogo-gray-1-opacity"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-start justify-start md:w-1/2 md:ml-1">
          <div
            class="flex items-center mb-3.5 md:bg-agrogo-green-4-5 w-full md:h-16 md:px-4 md:mb-2"
          >
            <p
              class="font-nexa font-bold text-white text-left text-sm md:text-lg lg:text-2xl"
            >
              <span class="icon-signo-pesos leading-3 pr-2"></span> Precio Total
              <span
                class="font-dinpro font-normal text-xs text-agrogo-gray-1 lg:text-base pl-10 md:pl-0"
                >(Pesos Colombianos)</span
              >
            </p>
          </div>
          <div class="w-full md:bg-agrogo-green-5 md:h-20 md:px-2 md:mb-3">
            <div class="flex items-center">
              <div class="grid grid-cols-2 md:h-14">
                <div class="flex items-center justify-start ml-6">
                  <a class="font-nexa text-white text-xs text-left md:text-lg"
                    >Desde</a
                  >
                </div>
                <div class="flex items-center justify-center">
                  <input
                    id="price_min"
                    name="price_min"
                    type="text"
                    v-model="formData.price_min"
                    class="input-standard-transparent"
                    @input="formatPrice1()"
                    @blur="validationPrice1"
                  />
                </div>
              </div>
            </div>
            <div>
              <p
                class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                v-if="isValidationPrice1"
              >
                El precio Desde no debe ser superior al Hasta.
              </p>
              <p
                class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
                v-else
              >
                &nbsp;
              </p>
            </div>
          </div>
          <div
            class="w-full mb-12 md:bg-agrogo-green-5 md:h-20 md:px-2 md:mb-8"
          >
            <div class="flex items-center">
              <div class="grid grid-cols-2 md:h-14">
                <div class="flex items-center justify-start ml-6">
                  <a class="font-nexa text-white text-xs text-left md:text-lg"
                    >Hasta</a
                  >
                </div>
                <div class="flex items-center justify-center">
                  <input
                    id="price_max"
                    name="price_max"
                    type="text"
                    v-model="formData.price_max"
                    class="input-standard-transparent"
                    @input="formatPrice2()"
                    @blur="validationPrice2"
                  />
                </div>
              </div>
            </div>
            <div>
              <p
                v-if="isValidationPrice2"
                class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
              >
                El precio Hasta no debe ser inferior al Desde.
              </p>
              <p
                v-else
                class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
              >
                &nbsp;
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CONTENEDOR BOTONES DE NAVEGACION -->
    <div
      v-if="!isBusy"
      class="flex items-center justify-center bg-agrogo-black-2 space-x-4 md:space-x-4 lg:space-x-4 pb-14 md:bg-agrogo-green-2 md:pb-8"
    >
      <button
        @click.prevent="() => $emit('previus', 'p1')"
        type="button"
        class="btn-nav-leads"
      >
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-xs mr-1 leading-none md:mr-2 md:text-sm"
        ></span>
        Anterior
      </button>
      <button
        @click="saveDataLead()"
        :disabled="
          isBusy ||
          $v.$invalid ||
          isValidationPrice1 ||
          isValidationPrice2 ||
          isValidationArea1 ||
          isValidationArea2
        "
        type="button"
        class="btn-nav-leads"
      >
        Siguiente
        <span
          class="icon-desplegar flex items-center transform -rotate-90 text-xs ml-1 leading-none md:ml-2 md:text-sm"
        ></span>
      </button>
    </div>
  </section>
</template>

<script>
import { generateHash } from "@/utils";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";

export default {
  inject: ["provider"],
  computed: {
    ...mapState("leadSteps", ["leadStep1"]),
  },
  data() {
    return {
      $v: useVuelidate(),
      /**
       * save department list
       * @type {Array<Object>}
       */
      departments: [],
      /**
       * save municipalities list
       * @type {Array<Object>}
       */
      municipalities: [],
      /**
       * Save the department id
       * @type {Number | Object}
       */
      selectedDepart: [],
      /**
       * Save the municipality id
       * @type {Number | Object}
       */
      selectedMunicipality: [],
      /**
       *Allows access to all the methods that establish the connection to Axios for requests to the agrogo api
       * @type {Object}
       */
      repository: this.provider.publishFarmRepository,
      /**
       * Turn loading gif on or off
       * @type {Boolean}
       */
      isBusy: false,
      /**
       * Save the form data
       * @type {Object}
       */
      formData: {
        area_min: null,
        area_max: null,
        price_min: null,
        price_max: null,
        departmentId: [],
        municipality_id: [],
      },
      /**
       * save the id of the previous department to perform validation
       * @type {Number | Object}
       */
      idDepartamentPrevius: null,
      /**
       * Enable or disable validation messages
       * @type {Boolean}
       */
      isValidationPrice1: false,
      /**
       * Enable or disable validation messages
       * @type {Boolean}
       */
      isValidationPrice2: false,
      /**
       * Enable or disable validation messages
       * @type {Boolean}
       */
      isValidationArea1: false,
      /**
       * Enable or disable validation messages
       * @type {Boolean}
       */
      isValidationArea2: false,
      vueSelectEvent: null,
    };
  },
  validations() {
    return {
      formData: {
        area_min: {
          required,
        },
        area_max: {
          required,
        },
        price_min: {
          required,
        },
        price_max: {
          required,
        },
        departmentId: {
          required,
        },
        municipality_id: {
          required,
        },
      },
    };
  },
  methods: {
    ...mapActions("leadSteps", ["actionLeadStep1"]),
    /**
     * consult the agrogo api and bring the information of all the departments of Colombia
     * @returns {void}
     */
    async loadDepartments() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllDeparts(dataAditional);

        me.departments = data;
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    /**
     * consult the agrogo api and return all the municipalities filtered by department
     * @param {Number} id from the Department
     * @returns {void}
     */
    async loadMunicipies() {
      const me = this;
      me.resetSelect();
      me.formData.departmentId.forEach((el) => {
        me.municipalities = [...me.municipalities, ...el.municipalities];
      });
      me.municipalities.sort((a, b) => a.name.localeCompare(b.name));
    },
    /**
     * reset the array containing the list of departments
     * @returns {void}
     */
    resetSelect() {
      const me = this;
      me.municipalities = [];
    },
    /**
     * formats all the prices of the input Price1 as a thousand and $ sign
     * @returns {void}
     */
    formatPrice1() {
      const me = this;
      let price = 0;

      if (me.formData.price_min.split("$")[1]) {
        me.formData.price_min = me.formData.price_min
          .split("$")[1]
          .split(".")
          .join("")
          .replace(/\D/g, "");

        if (isNaN(me.formData.price_min)) me.formData.price_min = 0;
      } else {
        if (isNaN(me.formData.price_min)) me.formData.price_min = 0;
      }

      const formatterPeso = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });

      price = formatterPeso.format(me.formData.price_min);
      me.formData.price_min = price;
    },
    /**
     * formats all the prices of the input Price2 as a thousand and $ sign
     * @returns {void}
     */
    formatPrice2() {
      const me = this;
      let price = 0;

      if (me.formData.price_max.split("$")[1]) {
        me.formData.price_max = me.formData.price_max
          .split("$")[1]
          .split(".")
          .join("")
          .replace(/\D/g, "");

        if (isNaN(me.formData.price_max)) me.formData.price_max = 0;
      } else {
        if (isNaN(me.formData.price_max)) me.formData.price_max = 0;
      }

      const formatterPeso = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });

      price = formatterPeso.format(me.formData.price_max);
      me.formData.price_max = price;
    },
    /**
     * formats all the prices of the input validationPrice1 as a thousand and $ sign
     * @returns {void}
     */
    validationPrice1() {
      const me = this;
      let isError = false;
      let price1 = 0;
      let price2 = 0;
      if (me.formData.price_min && me.formData.price_min.split("$")[1]) {
        price1 = me.formData.price_min.split("$")[1].split(".").join("");
      }

      if (me.formData.price_max && me.formData.price_max.split("$")[1]) {
        price2 = me.formData.price_max.split("$")[1].split(".").join("");
      }

      if (Number(price1) > Number(price2) && Number(price2) > 0) {
        isError = true;
        me.formData.price_min = 0;
      } else {
        isError = false;
      }

      me.isValidationPrice1 = isError;
    },
    /**
     * formats all the prices of the input validationPrice2 as a thousand and $ sign
     * @returns {void}
     */
    validationPrice2() {
      const me = this;
      let isError = false;
      let price1 = 0;
      let price2 = 0;

      if (me.formData.price_min && me.formData.price_min.split("$")[1]) {
        price1 = me.formData.price_min.split("$")[1].split(".").join("");
      }

      if (me.formData.price_max && me.formData.price_max.split("$")[1]) {
        price2 = me.formData.price_max.split("$")[1].split(".").join("");
      }

      if (Number(price2) < Number(price1) && Number(price2) > 0) {
        isError = true;
        me.formData.price_max = 0;
      } else {
        isError = false;
      }

      me.isValidationPrice2 = isError;
    },
    /**
     * formats all the prices of the input formatArea1 as a thousand and $ sign
     * @returns {void}
     */
    formatArea1() {
      const me = this;
      const formatter = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });
      const area10 = me.formData.area_min.split(",");
      if (area10.length > 2) {
        me.formData.area_min = `${area10[0]}${
          area10[1] !== "" ? `,${area10[1]}` : ","
        }`;
      }
      let area = me.formData.area_min.replace(/[^\d,.]/g, "");
      let area2 = me.formData.area_min.replace(/[^\d,.]/g, "");
      let attribute = 0;
      let isValidate = true;

      if (me.formData.area_min.split(".")[1]) {
        area = me.formData.area_min
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }

      if (me.formData.area_min.split(",")[1]) {
        if (me.formData.area_min.split(".")[1]) {
          const area3 = me.formData.area_min
            .split(",")[0]
            .split(".")
            .join("")
            .replace(/[^\d,.]/g, "");
          area2 = formatter.format(area3);
        } else {
          const area3 = me.formData.area_min
            .split(",")[0]
            .replace(/[^\d,.]/g, "");
          area2 = formatter.format(area3);
        }
        isValidate = false;
        let t = area.toString();
        let regex = /(\d*.\d{0,1})/;
        const attribute2 = t.match(regex)[0];
        attribute = `${area2.split(",")[0]},${attribute2.split(",")[1]}`;
      } else {
        area = me.formData.area_min
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }
      if (isNaN(attribute.split(".").join("")) && isValidate) attribute = area2;
      me.formData.area_min = attribute;
    },
    /**
     * formats all the prices of the input formatArea2 as a thousand and $ sign
     * @returns {void}
     */
    formatArea2() {
      const me = this;
      const formatter = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });
      const area10 = me.formData.area_max.split(",");
      if (area10.length > 2) {
        me.formData.area_max = `${area10[0]}${
          area10[1] !== "" ? `,${area10[1]}` : ","
        }`;
      }
      let area = me.formData.area_max.replace(/[^\d,.]/g, "");
      let area2 = me.formData.area_max.replace(/[^\d,.]/g, "");
      let attribute = 0;
      let isValidate = true;

      if (me.formData.area_max.split(".")[1]) {
        area = me.formData.area_max
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }

      if (me.formData.area_max.split(",")[1]) {
        if (me.formData.area_max.split(".")[1]) {
          const area3 = me.formData.area_max
            .split(",")[0]
            .split(".")
            .join("")
            .replace(/[^\d,.]/g, "");
          area2 = formatter.format(area3);
        } else {
          const area3 = me.formData.area_max
            .split(",")[0]
            .replace(/[^\d,.]/g, "");
          area2 = formatter.format(area3);
        }
        isValidate = false;
        let t = area.toString();
        let regex = /(\d*.\d{0,1})/;
        const attribute2 = t.match(regex)[0];
        attribute = `${area2.split(",")[0]},${attribute2.split(",")[1]}`;
      } else {
        area = me.formData.area_max
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }

      if (isNaN(attribute.split(".").join("")) && isValidate) attribute = area2;
      me.formData.area_max = attribute;
    },
    /**
     * formats all the prices of the input validationArea1 as a thousand and $ sign
     * @returns {void}
     */
    validationArea1() {
      const me = this;
      let isError = false;
      let area1 = me.formData.area_min;
      let area2 = me.formData.area_max;

      if (me.formData.area_min && me.formData.area_min.split(".")[1]) {
        area1 = me.formData.area_min.split(".").join("");
      }

      if (me.formData.area_min && me.formData.area_min.split(",")[1]) {
        area1 = me.formData.area_min.split(".").join("").split(",").join(".");
      }

      if (me.formData.area_max && me.formData.area_max.split(".")[1]) {
        area2 = me.formData.area_max.split(".").join("").split(",").join(".");
      }

      if (me.formData.area_max && me.formData.area_max.split(",")[1]) {
        area2 = me.formData.area_max.split(".").join("").split(",").join(".");
      }

      if (parseFloat(area1) > parseFloat(area2) && parseFloat(area2) > 0) {
        isError = true;
        me.formData.area_min = 0;
      } else {
        isError = false;
      }

      me.isValidationArea1 = isError;
    },
    /**
     * formats all the prices of the input validationArea2 as a thousand and $ sign
     * @returns {void}
     */
    validationArea2() {
      const me = this;
      let isError = false;
      let area1 = me.formData.area_min;
      let area2 = me.formData.area_max;

      if (me.formData.area_min && me.formData.area_min.split(".")[1]) {
        area1 = me.formData.area_min.split(".").join("");
      }

      if (me.formData.area_min && me.formData.area_min.split(",")[1]) {
        area1 = me.formData.area_min.split(".").join("").split(",").join(".");
      }

      if (me.formData.area_max && me.formData.area_max.split(".")[1]) {
        area2 = me.formData.area_max.split(".").join("").split(",").join(".");
      }

      if (me.formData.area_max && me.formData.area_max.split(",")[1]) {
        area2 = me.formData.area_max.split(".").join("").split(",").join(".");
      }

      if (parseFloat(area2) < parseFloat(area1) && parseFloat(area2) > 0) {
        isError = true;
        me.formData.area_max = 0;
      } else {
        isError = false;
      }

      me.isValidationArea2 = isError;
    },
    /**
     * save the form information in the state leadStep1 using the action
     * @returns {void}
     */
    saveDataLead() {
      const me = this;
      me.actionLeadStep1(me.formData);
      me.$emit("next", "n1");
    },
    /**
     * retrieves the information from the state leadStep1 to assign it to the form
     * @returns {void}
     */
    getDataLead() {
      const me = this;
      if (me.leadStep1 != null) {
        me.formData = me.leadStep1;
      }
    },
    /**
     * perform the formula to convert hectares to squares, blocks and bushels
     * @returns {void}
     */
    hectaresBlocks(value) {
      if (value) {
        let isValidate = true;
        const formatter = new Intl.NumberFormat("es-CO", {
          minimumFractionDigits: 0,
        });

        if (value.split(",")[1]) {
          isValidate = false;
          value = value.split(".").join("").split(",").join(".");
        }

        if (isNaN(value.split(".").join(""))) {
          value = 0;
        } else if (value.split(".")[1] && isValidate) {
          value = value.split(".").join("");
        }

        value = (value * (1 / 0.64)).toFixed(1);
        let result = formatter.format(value);
        return result;
      }
    },
  },
  async mounted() {
    const me = this;
    await me.loadDepartments();
    await me.getDataLead();
  },
};
</script>

<style lang="css">
.multiselect {
  min-height: 22px !important;
}
.multiselect__tags {
  position: relative;
  top: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #ffffff;
  display: flex;
  text-align: start;
  border-width: 1px;
  background-color: transparent;
  font-family: "DINPro";
  border-color: #f5ce35;
  font-weight: 400;
  min-height: 22px !important;
  padding: 0;
  margin: 0;
  width: 82%;
  padding-left: 5px;
}
.multiselect__placeholder {
  padding-top: 0px;
  margin-bottom: 0px;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  color: #ffffff;
  padding-right: 4px;
  text-align: end;
  display: flex;
  align-items: center;
  justify-items: flex-end;
}
.multiselect__tag {
  color: white;
  text-align: left !important;
  background-color: #f5ce35 !important;
  font-family: "DINPro";
  font-size: 0.75rem !important;
  line-height: 1rem;
  font-weight: 700;
  margin: 2px;
  /* width: 70%; */
  min-height: 22px;
  text-align: end;
}
.multiselect__select {
  border-color: transparent;
  min-height: 22px;
  height: 100%;
  background: #f5ce35;
  color: white;
  top: 0px;
  width: 22px;
  padding: 0;
  transition: transform 0s ease;
  left: 84%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.multiselect__select:before {
  display: flex;
  right: 0;
  top: 0;
  color: rgb(153, 7, 7) !important;
  margin-top: 0px;
  border-style: solid;
  border-width: 6px 5px 0 6px;
  border-color: rgb(255, 255, 255) transparent transparent transparent;
  content: "";
}
.multiselect__content-wrapper {
  background-color: #153e35;
  font-family: "DINPro";
  border-color: transparent;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  color: #ffffff;
  width: 100%;
  min-height: 22px !important;
}
.multiselect__option--highlight {
  background: #f5ce35;
  outline: none;
  color: #fff;
}
.multiselect__option--highlight:hover {
  background-color: #4c9800;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.multiselect__option--selected {
  background-color: #f5ce35;
  color: white;
}
@media (min-width: 768px) {
  .multiselect {
    min-height: 32px !important;
    font-size: 1rem;
    line-height: 1.449rem;
  }
  .multiselect__tags {
    min-height: 32px !important;
    font-size: 1rem;
    line-height: 1.449rem;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-left: 15px;
  }
  .multiselect__placeholder {
    font-size: 1rem;
    line-height: 1.449rem;
  }
  .multiselect__tag {
    font-size: 1rem;
    line-height: 1.449rem;
    min-height: 32px;
  }
  .multiselect__select {
    min-height: 32px;
    height: 100%;
    width: 32px;
  }
  .multiselect__content-wrapper {
    font-size: 1rem;
    line-height: 1.449rem;
    min-height: 32px !important;
  }
}
</style>
