import BaseModuleRepositoryInterface from "@/repositories/interfaces/BaseModuleRepositoryInterface";

const bind = (repositoryName, Interface) => {
  return {
    ...Object.keys(Interface).reduce((prev, method) => {
      const resolveableMethod = async (...args) => {
        const repository = await import(`@/repositories/${repositoryName}`);
        return repository.default[method](...args);
      };
      return { ...prev, [method]: resolveableMethod };
    }, {}),
  };
};

export default {
  authRepository: bind("AuthRepository", BaseModuleRepositoryInterface),
  homeRepository: bind("HomeRepository", BaseModuleRepositoryInterface),
  publishFarmRepository: bind(
    "PublishFarmRepository",
    BaseModuleRepositoryInterface
  ),
  publishFarmEditRepository: bind(
    "PublishFarmEditRepository",
    BaseModuleRepositoryInterface
  ),
  leadRepository: bind("LeadRepository", BaseModuleRepositoryInterface),
  myFarmRepository: bind("MyFarmRepository", BaseModuleRepositoryInterface),
  transactionRepository: bind(
    "TransactionRepository",
    BaseModuleRepositoryInterface
  ),
  farmDetailRepository: bind(
    "FarmDetailRepository",
    BaseModuleRepositoryInterface
  ),
  footerRepository: bind("FooterRepository", BaseModuleRepositoryInterface),
  listOfFarmRepository: bind(
    "ListOfFarmRepository",
    BaseModuleRepositoryInterface
  ),
  profileRepository: bind("ProfileRepository", BaseModuleRepositoryInterface),
  verifyTelephoneRepository: bind(
    "VerifyTelephoneRepository",
    BaseModuleRepositoryInterface
  ),
  favoriteRepository: bind("FavoriteRepository", BaseModuleRepositoryInterface),
};
