import { createStore } from "vuex";
import user from "./modules/user";
import publishFarm from "./modules/publishFarm";
import farmTypes from "./modules/farmTypes";
import leadSteps from "./modules/leadSteps";
import datepickerAuction from "./modules/datepickerAuction";
import preLaunch from "./modules/preLaunch";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    publishFarm,
    farmTypes,
    leadSteps,
    datepickerAuction,
    preLaunch,
  },
});
